import { useQuery, UseQueryOptions } from "react-query"
import { z } from "zod"

import { TODataParams } from "types"
import { operations } from "types/apiSchema"
import { fetchJson } from "utils/network"

import { certificateKeys, ECertificateStatuses } from "."

const BasicCertificateSchema = z
  .object({
    CertificateID: z.number(),
    ModDate: z.string(),
    CreaDate: z.string(),
    StatusID: z.number(),
    StatusName: z.string(),
    StatusCode: z.nativeEnum(ECertificateStatuses),
    TypeID: z.number(),
    TypeName: z.string(),
  })
  .passthrough()

export type BasicCertificate = z.infer<typeof BasicCertificateSchema>

export type TAllCertificatesQueryParams = TODataParams &
  operations["Zeugnisse_GetCertificates"]["parameters"]["query"]

const getCertificatesAll = async (queryParams?: TODataParams) => {
  const response = await fetchJson<
    BasicCertificate[],
    true,
    TAllCertificatesQueryParams
  >("zeugnisse/getcertificates", {
    fullResponse: true,
    queryParams: {
      $inlinecount: "allpages",
      ...queryParams,
    },
  })

  const maybeBasicCertificates = BasicCertificateSchema.array().safeParse(
    response.data
  )

  if (!maybeBasicCertificates.success) {
    throw new Error("Failed to parse certificates")
  }

  return maybeBasicCertificates.data
}

export const useCertificatesAll = ({
  options,
  queryParams,
}: {
  options?: UseQueryOptions<BasicCertificate[], Error>
  queryParams?: TODataParams
}) => {
  return useQuery({
    queryKey: [certificateKeys.all, queryParams?.$filter],
    queryFn: () => getCertificatesAll(queryParams),
    ...options,
  })
}
